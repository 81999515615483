<template>
  <div class="columns form is-mini-gap is-multiline">
    <div v-show="!property" class="column is-narrow">
      <InputProperty v-model:value="propertyId" style="min-width: 200px" @change="handleFilter" />
    </div>
    <div class="column is-narrow">
      <a-range-picker
        v-model:value="range"
        style="width: 100%"
        :size="size"
        :format="dateFormat"
        @change="handleFilter"
      >
        <a-input :value="rangeString" :placeholder="$t('Reported time')" :size="size" readonly>
          <template #prefix>
            <CalendarFilled />
          </template>
        </a-input>
      </a-range-picker>
    </div>
    <div class="column is-narrow">
      <a-select
        v-model:value="status"
        :placeholder="$t('Status')"
        style="min-width: 200px; width: 100%"
        :size="size"
        @change="handleFilter"
      >
        <a-select-option v-for="o in statusOptions" :key="o.label" :value="o.value">
          {{ o.label }}
        </a-select-option>
      </a-select>
    </div>
    <div class="column">
      <a-button
        v-show="propertyId || status || range.length"
        :size="size"
        style="min-width: 110px"
        type="default"
        @click="handleClear"
      >
        {{ $t('Clear') }}
      </a-button>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Reported time": "Reported time",
"Clear": "Clear",
},
"ja": {
"Reported time": "報告時間",
"Clear": "クリア",
}
}
</i18n>

<script>
import debounce from 'lodash/debounce';
import { CalendarFilled } from '@ant-design/icons-vue';
import InputProperty from '@/components/InputProperty';
import { cleanObject } from '@/utils/util';

export default {
  name: 'SearchForm',
  components: {
    InputProperty,
    CalendarFilled,
  },
  props: {
    property: {
      type: Object,
      default: null,
    },
    size: {
      type: String,
      default: 'large',
    },
    dateFormat: {
      type: String,
      default: 'MM/DD/YYYY',
    },
  },
  emits: ['filter'],
  data() {
    return {
      propertyId: undefined,
      range: [],
      status: undefined,
      statusOptions: [
        { label: this.$t('fixed'), value: 'fixed' },
        { label: this.$t('not fixed'), value: 'not fixed' },
      ],
    };
  },
  computed: {
    rangeString() {
      return this.range.length
        ? `${this.$filters.date(this.range[0], { format: this.dateFormat })} ~ ${this.$filters.date(
          this.range[1],
          { format: this.dateFormat },
        )}`
        : '';
    },
    formattedRange() {
      const dateFormat = 'YYYY-MM-DD';
      return this.range.length
        ? `${this.$filters.date(this.range[0], { format: dateFormat })},${this.$filters.date(
          this.range[1],
          { format: dateFormat },
        )}`
        : undefined;
    },
  },
  mounted() {
    this.propertyId = this.$route.query.propertyId || undefined;
    this.range = this.$route.query.range ? this.$route.query.range.split(',') : [];
    this.status = this.$route.query.status || undefined;
  },
  methods: {
    // eslint-disable-next-line func-names
    handleFilter: debounce(async function () {
      const query = cleanObject({
        ...this.$route.query,
        propertyId: this.propertyId,
        range: this.formattedRange,
        status: this.status,
        page: 1, // force page 1
      });

      await this.$router.push({ query });
      this.$emit('filter');
    }, 600),
    handleClear() {
      this.propertyId = undefined;
      this.status = undefined;
      this.range = [];
      this.handleFilter();
    },
  },
};
</script>

<style scoped></style>
